.Payment{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Pay .Group{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    width: 80%;
}

.Pay{
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 2%;
    padding: 2%;
    justify-content: space-between;
}

.Payment-Content{
    text-align: center;
}

.Payment-Title{
    font-size: 4rem;
    font-weight: 600;
    background: linear-gradient(to left, #ee0979, #ff6a00);
    background-clip: text;
    color: transparent;
}

.Pay table{
    padding: 2%;
    width: 100%;
}

.Pay th, .Pay td{
    padding: 2%;
    text-align: left;
}

.Info{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Info input{
    width: 50%;
    padding: 1%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 1%;
    outline: none;
    font-weight: 500;
    font-size: 1rem;
}

.Code{
    width: 100%;
    display: flex;
    flex-direction: column;
    font-style: italic;
    background-color: #E94057;
    color: #FFF;
    padding: 2%;
    border-radius: 5px;
}

.Code p{
    font-size: 0.9rem;
}

hr{
    margin-top: 5%;
    border: 1px solid #ff6a00;
    width: 100%;
}

.Bank img{
    width: 100%;
}

.Bank{
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 2%;
    margin: 2%;
}

.Bank table{
    width: 100%;
}

.Bank th, .Bank td{
    padding: 2%;
    text-align: left;
}

.Bank h3{
    padding: 2%;
}

.cTrans{
    background-color: #dcdcdc;
    color: rgb(105, 105, 105);
    border-radius: 5px;
    margin: 2%;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cTrans button{
    background-color: white;
    color: #ee0979;
    border-radius: 5px;
    margin: 2%;
    width: 20%;
}

.cTrans button:hover{
    background-color: #ee0979;
    color: white;
}

@media screen and (max-width: 768px){
    .Choice-Content{
        width: 100%;
        margin: 5%;
        height: 100%;
    }

    .Premium{
        width: 100%;
        height: 100%;
        margin-bottom: 10vh;
    }
}
    