@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;900&display=swap');

.Home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
    /* position: fixed; */
    z-index: 1;
    /* background: linear-gradient(to left, #f8577a, #ff5858); */
    overflow: hidden;
    overflow-x: hidden;
  }

.icons {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center
}

.css-1qiciin-MuiButtonBase-root-MuiFab-root:hover{
    background-color: #942837 !important;
}


@media screen and (max-height: 540px) {
  .frame {
    width: 54vh;
    height: 81vh;
    font-size: 13px;
  }
}

@media screen and (max-height: 440px) {
  .frame {
    font-size: 8px;
  }
}


/* --- */
/* .card{
    display: flex;
    flex-direction: column;
    max-width: 350px;
    max-height: 550px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}

.card .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.card img{
    width: 350px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 550px;
    object-fit: cover;
    pointer-events: none;
} */


.app {
  overflow: hidden;
}

.app>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row>* {
  margin: 5px;
}

h1 {
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}

h2 {
  color: #fff;
}

.swipe {
  position: absolute;
}

.cardContainer {
  width: 100px;
  max-width: 260px;
  height: 300px;
}

.card {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 260px;
  height: 300px;
  box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  width: 100%;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

@keyframes popup {
  0%   { transform: scale(1,1) }
  10%  { transform: scale(1.1,1.1) }
  30%  { transform: scale(.9,.9) }
  50%  { transform: scale(1,1) }
  57%  { transform: scale(1,1) }
  64%  { transform: scale(1,1) }
  100% { transform: scale(1,1) }
}

/* Res */
@media screen and (max-width: 768px){
    .card{
      width: 75%;
      height: 70%;
      background: linear-gradient(to left, #f8577a, #ff5858);
      color: #fff;
      margin-left: 15%;
    }
    .card img{
        width: 100%;
        height: 700%;
    }
    .Home{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        /* background: linear-gradient(to left, #f8577a, #ff5858); */
    }

    .icons {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        margin-left: 5%;
    }

}
 .icons{
    margin-top: 300px;
 }