.Welcome{
    background-image: url('../Images/Welcome.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
}

@media screen and (max-width: 768px) {
    .Welcome{
        background-image: url('../Images/WelcomeMobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100vh;
        width: 100vw;
    }
}
    