
.Facetime{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Screen iframe{
    width: 1550px;
    height: 800px;
    border: none;
}

.ToolFacetime{
    display: flex;
    flex-direction: row;
    width: 1550px;
    justify-content: center;
    align-items: center;
}

.ToolFacetime .Icon{
    margin-left: 5%;
    margin-right: 5%;
}

@media screen and (max-width: 1550px){
    .Screen iframe{
        width: 100%;
        height: 100%;
    }
    .ToolFacetime{
        width: 100%;
    }
}