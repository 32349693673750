/* .Messages{
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
} */

.Messages h1{
    color: #000;
}

.Messages{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    flex-wrap: wrap;
    overflow: hidden;
    position: fixed;
}

.Chat{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    flex-wrap: wrap;
    width: 70%;
    /* height: 89.9vh; */
}

.Chat .Name{
    /* background-color: #dcdcdc; */
    /* border: 1px solid #dcdcdc; */
    box-shadow: 0 2px 0 0 #e7e7e7;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1%;
}

.WChat{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.text input{
    width: 50rem;
    border: 1px solid rgb(223, 219, 219);
    outline: none;
    padding: 2%;
    border-radius: 10px;
    font-size: 1rem;
}

.History{
    width: 100%;
    height: 80vh;
    overflow: auto;
    padding: 1%;
}

.Send{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 3%;
}

.Send .Time{
    font-size: 0.8rem;
    color: #000;
    margin-right: 1%;
}

.Send .Text{
    background-color: #dcdcdc;
    padding: 1%;
    border-radius: 10px;
    margin-right: 1%;
    max-width: 40%;    
}

.Received{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 3%;
}

.Received .Time{
    font-size: 0.8rem;
    color: #000;
    margin-left: 1%;
}

.Received .Text{
    background-color: #E94057;
    color: #FFFFFF;
    padding: 1%;
    border-radius: 10px;
    margin-left: 1%;
    max-width: 40%;
}


.Chat .ToolBox{
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* bottom: 0;
    position: sticky; */
    /* box-shadow: 0 0 2px 0 #e7e7e7; */
    background-color: #f0f0f0;
    width: 100%;
    padding: 0.5%;
}


/* ========== */

.Profile{
    /* display: flex; */
    flex-direction: column;
    flex-wrap: wrap;
    width: 30%;
    justify-content: flex-end
}

.Album{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 2%;
    /* padding: 3%; */
    align-content: center
}

.Main img{
    width: 100%;
    padding: 3%;
}

.Album img{
    max-width: 145px ;
    max-height: 145px ;
    object-fit: contain ;
}

.Info .Name{
    padding: 3%;
}

.Info .Name span, .Info .Name h3{
    font-weight: bold;
    font-size: 1.5rem;
}

.Info .Name p{
    font-size: 1rem;
    color: #8f8f8f;
}




/* Media */
@media screen and (max-width: 768px){
    .Messages{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: fixed;
        top:7vh;
        width: 100%;
    }

    .History{
        height: 60vh;
        margin-bottom: 30vh;
    }

    .Chat{
        width: 100%;
    }

    .Profile{
        display: none;
    }

    .Send .Text{
        background-color: #dcdcdc;
        padding: 1%;
        border-radius: 10px;
        margin-right: 1%;
        max-width: 70%;    
        padding: 1%;
    }

    .Received .Text{
        background-color: #E94057;
        color: #FFFFFF;
        padding: 1%;
        border-radius: 10px;
        margin-right: 1%;
        max-width: 70%;    
        padding: 1%;
    }

    .Chat .ToolBox{
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #f0f0f0;
        padding: 3%;
        position: fixed;
        bottom: 8%;
        /* height: 7vh; */
    }

    .ToolBox .file{
        display: none;
    }

    .ToolBox input{
        width: 15rem;
    }

    .Chat .Name{
        height: 7vh;
    }
}
    

    
