.Login{
    background-image: url(../../Images/BeApp.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    animation: move 3s ease-in-out infinite alternate;
}

@keyframes move{
    0%{
        background-position-x: 10%;
    }
    100%{
        background-position-x: 100%;
    }
}

.Login .Group{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.Login img{
        width: 100%;
        margin-top: -20%;
}

.Login h1{
    margin-top: -25% !important;
    text-align: center;
    font-size: 3rem;
    color: white;
}

.Login h2{
    text-align: center;
    font-size: 2rem;
    color: white;
}

.Login button{
    width: 15rem;
    margin-top: 5% !important;
    background-color: #E94057 !important;
}

@media screen and (max-width: 768px){

    
    /* Tạo animation cho backgroud chuyển động ảnh từ trái sang phải */
    @keyframes move{
        0%{
            background-position-x: 10%;
        }
        100%{
            background-position-x: 100%;
        }
    }

    

    .Login{
        background-image: url(../../Images/BeApp.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-position-x: 10%;
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;
        animation: move 15s ease-in-out infinite;; 
    }

    .Login .Group{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .Login img{
        margin-top: -20%;
        width: 100%;
    }
    
    .Login h1{
        /* margin-top: -25% !important; */
        text-align: center;
        font-size: 3rem;
        color: white;
    }
    
    .Login h2{
        text-align: center;
        font-size: 2rem;
    }
    
    .Login button{
        width: 15rem;
        /* margin-top: 5% !important; */
        background-color: #E94057 !important;
        padding: 5%;
    }
}
    